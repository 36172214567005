import React from 'react';

import { TinaCreditCard } from '@/components/blocks/frames/CreditCard/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const creditCard = {
    Component: (props: IBlockProps) => <TinaCreditCard {...props} />,
    template: {
        label: 'Кредитные каникулы, Карты',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
