import React from 'react';

import { TinaUniversalExternalFormScript } from '@/components/blocks/frames/UniversalExternalFormScript/index.tina';

export const universalExternalFormScriptBlock = {
    Component: props => <TinaUniversalExternalFormScript {...props} />,
    template: {
        label: 'Универсальный блок для внешнего скрипта с формой',
        defaultItem: () => ({
            title: 'Заголовок',
            script:
                '<script src="https://my.pochtabank.ru/sdk/v1/product-lead.js"></script>\n' +
                '\n' +
                '<script type="text/javascript">\n' +
                "  window.PBSDK.productLead.mountTo('#product-lead-form-container', {theme: 'light', productType: 'Card', leadDescription:'на дебетовую карту Вездедоход'});\n" +
                "  window.PBSDK.productLead.on('submit', function(appId) {\n" +
                '    var dataLayer = window.dataLayer || [];\n' +
                '    dataLayer.push({\n' +
                "      'event': 'universalEvent',\n" +
                "      'eventCategory': 'DebitCardForm@Vezdedohod',\n" +
                "      'contextId': appId\n" +
                '    });\n' +
                '  });\n' +
                '</script>',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Название',
            },
            {
                name: 'script',
                component: 'textarea',
                label: 'Script (пример заполнения:)',
            },
        ],
    },
};
