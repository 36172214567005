import React from 'react';

import { TinaPensionTransferV2 } from '@/components/blocks/frames/PensionTransferV2/index.tina';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

export const pensionTransferV2Block = {
    Component: (props: IBlockProps) => <TinaPensionTransferV2 {...props} />,
    template: {
        label: 'Перевод пенсии версия 2',
        defaultItem: () => ({
            title: 'Заголовок',
            gtm: {
                eventName: 'pbFormEvent',
                category: 'AppSent',
                action: '',
                label: '',
            },
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            { ...gtagSingleItemCustom('gtm', 'Цель на успешную отправку') },
        ],
    },
};
