import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

import cn from './CashLoanContent/style.module.sass';

export interface ICashLoan {
    _template?: 'cashLoan';
    title: string;
    desc?: string;
    hideCreditCalculator?: boolean;
}

const CashLoanContent = dynamic(async () => import('./CashLoanContent'), { ssr: false });

const CashLoan: React.FC<ICashLoan & { index: number }> = ({
    index,
    title = 'Заявка на кредит наличными',
    desc,
    hideCreditCalculator = true,
}) => {
    const [isView, setIsView] = useState(true);

    const handleView = () => {
        setIsView(false);
    };

    return (
        <div className="section">
            {isView && (
                <div className={clsx(cn.extWrapper, hideCreditCalculator && cn.hideCalc)}>
                    <CashLoanContent
                        title={title}
                        desc={desc}
                        index={index}
                        hideCreditCalculator={hideCreditCalculator}
                        handleView={handleView}
                    />
                </div>
            )}
        </div>
    );
};
export default withBlockLinks(CashLoan);
