import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

const CreditCardTypeCreditContent = dynamic(async () => import('./CreditCardTypeCreditContent'), {
    ssr: false,
});

export interface ICreditCardTypeCredit {
    _template: 'creditCardTypeCredit';
    title: string;
}

const CreditCardTypeCredit: React.FC<ICreditCardTypeCredit & { index: number }> = ({ title }) => (
    <div className="section">
        <CreditCardTypeCreditContent title={title} />
    </div>
);

export default withBlockLinks(CreditCardTypeCredit);
