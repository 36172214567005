import clsx from 'clsx';
import React, { useEffect } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface ICrmOffersContentItem {
    pos?: number;
    title: string;
    subtitle?: string;
    mark?: string;
    image: string;
    link?: string;
    gtag?: GTagEvent;
    gtagClick?: GTagEvent;
    gtagShow?: GTagEvent;
    moreLinkName?: string;
    moreLinkHref?: string;
}

export interface ICrmOffersDBOItem {
    title: string;
    type?: string;
    productName?: string;
    gtagClick?: GTagEvent;
    gtagShow?: GTagEvent;
}

type WrapperTag = 'div' | 'a';

const CrmOffersContentItem: React.FC<ICrmOffersContentItem> = ({
    pos,
    title,
    subtitle,
    image,
    link,
    gtag,
    gtagClick,
    gtagShow,
    moreLinkName,
    moreLinkHref,
    mark,
}) => {
    let WrapElement = 'div' as WrapperTag;
    let attributes = {};

    if (link) {
        WrapElement = 'a';
        attributes = { href: link };
    }

    useEffect(() => {
        if (gtagShow?.action) event(gtagShow);
    }, []);

    return (
        <AnimatedComponent className={cn.infoItem} classNameActive={cn.animationInit} role="presentation">
            <div className={clsx(cn.infoItemWrap, link && cn.link)}>
                <div className={cn.infoInnerWrapper}>
                    <div className={cn.infoLeft}>
                        <div className={cn.infoContent}>
                            <WrapElement {...attributes}>
                                <TextField className={cn.infoTitle} text={title} name={`content.list.[${pos}].title`} />
                            </WrapElement>
                            <TextField
                                className={cn.infoSubtitle}
                                text={subtitle}
                                name={`content.list.[${pos}].subtitle`}
                            />
                        </div>
                    </div>

                    <div className={clsx(cn.infoRight)}>
                        <div className={cn.infoImageWrapper}>
                            <div className={cn.infoImage}>
                                <CustomImage src={image} alt={title} mockWidth="60" mockHeight="60" fill />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={clsx(cn.infoInnerWrapper, cn.infoInnerWrapperBottom)}>
                    <div className={cn.infoLeft}>
                        <div className={cn.infoMarkWrapper}>
                            <TextField
                                text={mark}
                                name={`content.list.[${pos}].mark`}
                                isHTML
                                htmlValue={mark}
                                className={cn.infoMark}
                            />
                        </div>
                    </div>

                    <div className={clsx(cn.infoRight)}>
                        <Button
                            variant="btnBlueBorder"
                            type="link"
                            label="Оформить"
                            href={moreLinkHref}
                            onClick={() => {
                                if (gtagClick?.action) {
                                    event(gtagClick);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </AnimatedComponent>
    );
};

export default withBlockLinks(CrmOffersContentItem);
