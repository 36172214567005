import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';

import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useWindowScroll } from '@/hooks/useWindowScroll';

export const withExternalFrameView = <T,>(Component: React.FC<T>) => {
    const ExternalComponent: React.FC<T> = observer((props: T & { isVisible: boolean }) => {
        const { isVisible } = props;

        const { setIsExternalFrameView } = useLayoutStore();

        const blockLink = useRef<HTMLDivElement | null>(null);

        const scroll = useWindowScroll(0);

        const scrollOffset = 50;

        useEffect(() => {
            if (isVisible) {
                const blockPosition = blockLink?.current?.getBoundingClientRect().top;
                const blockHeight = blockLink?.current?.offsetHeight;

                const hideIfBlockInView =
                    blockPosition - scrollOffset < 0 && blockPosition > 0 - blockHeight - scrollOffset;

                setIsExternalFrameView(hideIfBlockInView);
            }
        }, [scroll, isVisible]);

        useEffect(() => {
            if (!isVisible) {
                setIsExternalFrameView(false);
            }
        }, [isVisible]);

        return (
            <div ref={blockLink}>
                <Component {...props} />
            </div>
        );
    });

    return ExternalComponent;
};
