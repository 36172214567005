import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IIosAppLink {
    _template: 'iosAppLink';
    title: string;
}

const IosAppLinkContent = dynamic(async () => import('./IosAppLinkContent'), { ssr: false });

const IosAppLink: React.FC<IIosAppLink> = ({ title }) => (
    <div className="section">
        <IosAppLinkContent title={title} />
    </div>
);

export default withBlockLinks(IosAppLink);
