type TItem = {
    image: string;
    title: string;
    description: string;
};

type TStaticData = Record<string, TItem>;

export const staticData: TStaticData = {
    CashRef: {
        image: '/img-next/png/crm/2.png',
        title: 'Рефинансирование',
        description: 'С услугой "Гарантированная ставка"',
    },
    TopUpCashNewAgreement: {
        image: '/img-next/png/crm/2.png',
        title: 'Рефинансирование',
        description: 'С услугой "Гарантированная ставка"',
    },
    Cash: {
        image: '/img-next/png/crm/1.png',
        title: 'Кредит наличными',
        description: 'С услугой "Гарантированная ставка"',
    },
    CashNotPreapproved: {
        image: '/img-next/png/crm/1.png',
        title: 'Кредит наличными',
        description: 'С услугой "Гарантированная ставка"',
    },
    CardPreapproved: {
        image: '/img-next/png/crm/3.png',
        title: 'Кредитная карта',
        description: '120 дней без % и бонусы за покупки',
    },
    CardNotPreapproved: {
        image: '/img-next/png/crm/3.png',
        title: 'Карта &laquo;ВездеДоход&raquo;',
        description: '120 дней без процентов',
    },
};

export const mockCrmOffersDBOData = {
    listDbo: [
        {
            title: 'Div Cash',
            type: 'Cash',
            productName: 'Div',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_div_cash',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_div_cash',
            },
        },
        {
            title: 'Div CashRef',
            type: 'CashRef',
            productName: 'Div',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_div_cashRef',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_div_cashRef',
            },
        },
        {
            title: 'Supersmart Cash',
            type: 'Cash',
            productName: 'Supersmart',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_supersmart_cash',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_supersmart_cash',
            },
        },
        {
            title: 'Supersmart CashRef',
            type: 'CashRef',
            productName: 'Supersmart',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_supersmart_cashRef',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_supersmart_cashRef',
            },
        },
        {
            title: 'Pract Cash',
            type: 'Cash',
            productName: 'Pract',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_pract_cash',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_pract_cash',
            },
        },
        {
            title: 'Pract CashRef',
            type: 'CashRef',
            productName: 'Pract',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_pract_cashRef',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_pract_cashRef',
            },
        },
        {
            title: 'Stable Cash',
            type: 'Cash',
            productName: 'Stable',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_stable_cash',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_stable_cash',
            },
        },
        {
            title: 'Quasi CashNotPreapproved',
            type: 'CashNotPreapproved',
            productName: 'Quasi',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_quasi_cashnotpreapproved',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_quasi_cashnotpreapproved',
            },
        },
        {
            title: 'Vezdedohod CardPreapproved',
            type: 'CardPreapproved',
            productName: 'Vezdedohod',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_vezdedohod_cardpreapproved',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_vezdedohod_cardpreapproved',
            },
        },
        {
            title: 'Vezdedohod CardNotPreapproved',
            type: 'CardNotPreapproved',
            productName: 'Vezdedohod',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_vezdedohod_cardnotpreapproved',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_vezdedohod_cardnotpreapproved',
            },
        },
        {
            title: 'TopUpCashNewAgreement',
            type: 'TopUpCashNewAgreement',
            productName: '',
            gtagShow: {
                action: 'offer_display',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_offer_display_TopUpCashNewAgreement',
            },
            gtagClick: {
                action: 'click_oformit',
                category: 'crm_offers_showing',
                label: 'main_crm_offers_showing_click_oformit_TopUpCashNewAgreement',
            },
        },
    ],
};
