import React from 'react';

import { TinaCashLoan } from '@/components/blocks/frames/CashLoan/index.tina';
import htmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

export const cashLoanBlock = {
    Component: (props: IBlockProps) => <TinaCashLoan {...props} />,
    template: {
        label: 'Онлайн заявка на кредит наличными',
        defaultItem: () => ({
            title: 'Заголовок',
            hideCreditCalculator: true,
        }),
        fields: [
            {
                name: 'title',
                component: htmlEditorFeatured,
                label: 'Заголовок',
            },
            {
                name: 'desc',
                component: 'text',
                label: 'Описание',
            },
            {
                name: 'hideCreditCalculator',
                component: 'toggle',
                label: 'Скрыть калькулятор',
            },
        ],
    },
};
