import { iosAppLinkBlock } from '@/tina/blocks/iosAppLink';
import { mapContainerForStaticCityBlock } from '@/tina/blocks/mapContainerStaticCity';
import { cardToCardBlock } from '@/tina/blocks/paymentGroups/_deprecated/cardToCard';
import { depositApplicationBlock } from '@/tina/blocks/paymentGroups/_deprecated/depositApplication';
import { loanRepaymentBlock } from '@/tina/blocks/paymentGroups/_deprecated/loanRepayment';
import { pushkinskayaCardBlock } from '@/tina/blocks/paymentGroups/_deprecated/pushkinskayaCard';
import { sberschetOpenBlock } from '@/tina/blocks/paymentGroups/_deprecated/sberschetOpen';
import { vddohodBlock } from '@/tina/blocks/paymentGroups/_deprecated/vddohod';
import { cashLoanBlock } from '@/tina/blocks/paymentGroups/cashLoan';
import { creditCard } from '@/tina/blocks/paymentGroups/creditCard';
import { creditCardTypecreditBlock } from '@/tina/blocks/paymentGroups/creditCardTypeCredit';
import { crmOffersBlock } from '@/tina/blocks/paymentGroups/crmOffers';
import { pensionTransferV2Block } from '@/tina/blocks/paymentGroups/pensionTransferV2';
import { vkladDesyatochkaBlock } from '@/tina/blocks/paymentGroups/vkladDesyatochka';
import { universalExternalFormScriptBlock } from '@/tina/blocks/universalExternalFormScript';
import { universalExternalIframeBlock } from '@/tina/blocks/universalExternalIframe';
import { ITinaBlocksConfig } from '@/types/tina/config';

export const tinaFrameConfig: ITinaBlocksConfig = {
    cardToCard: cardToCardBlock,
    loanRepayment: loanRepaymentBlock,
    cashLoan: cashLoanBlock,
    vddohod: vddohodBlock,
    depositApplication: depositApplicationBlock,
    sberschetOpen: sberschetOpenBlock,
    pushkinskayaCard: pushkinskayaCardBlock,
    creditCard,
    creditCardTypecredit: creditCardTypecreditBlock,
    universalExternalIframe: universalExternalIframeBlock,
    universalExternalFormScript: universalExternalFormScriptBlock,
    crmOffers: crmOffersBlock,
    mapContainerForStaticCity: mapContainerForStaticCityBlock,
    vkladDesyatochka: vkladDesyatochkaBlock,
    pensionTransferV2: pensionTransferV2Block,
    iosAppLink: iosAppLinkBlock,
};
