import React from 'react';

import { TinaSberschetOpen } from '@/components/blocks/frames/SberschetOpen/index.tina.deprecated';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

interface IExtendedBlockProps extends IBlockProps {
    index: number;
}

export const sberschetOpenBlock = {
    Component: (props: IBlockProps) => {
        const { data, index } = props;

        const extendedProps: IExtendedBlockProps = {
            ...props,
            data: {
                ...data,
                index,
            },
        };

        return <TinaSberschetOpen {...extendedProps} />;
    },
    template: {
        label: 'Онлайн заявка на открытие сберегательного счета',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            { ...gtagSingleItemCustom('gtagClick', 'Цель на успешную отправку') },
        ],
    },
};
