import React from 'react';

import { TinaUniversalExternalIframe } from '@/components/blocks/frames/UniversalExternalIframe/index.tina';

export const universalExternalIframeBlock = {
    Component: props => <TinaUniversalExternalIframe {...props} />,
    template: {
        label: 'Универсальный блок для внешнего айфрейма',
        defaultItem: () => ({
            title: 'Заголовок',
            frame: '<iframe title="Опрос" height="887" width="100%" src="https://ru.research.net/r/savingspochtabank"></iframe>',
        }),
        fields: [
            {
                name: 'frame',
                component: 'textarea',
                label: 'Код Iframe (пример заполнения:)',
            },
        ],
    },
};
