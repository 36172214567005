import React from 'react';

import { TinaPushkinskayaCard } from '@/components/blocks/frames/PushkinskayaCard/index.tina.deprecated';
import { gtagSingleItem } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

interface IExtendedBlockProps extends IBlockProps {
    index: number;
}

export const pushkinskayaCardBlock = {
    Component: (props: IBlockProps) => {
        const { data, index } = props;

        const extendedProps: IExtendedBlockProps = {
            ...props,
            data: {
                ...data,
                index,
            },
        };

        return <TinaPushkinskayaCard {...extendedProps} />;
    },
    template: {
        label: 'Онлайн заявка на кредит наличными',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            { ...gtagSingleItem },
        ],
    },
};
