import clsx from 'clsx';
import React from 'react';

import CrmOffersContentItem, {
    ICrmOffersContentItem,
    ICrmOffersDBOItem,
} from '@/components/blocks/frames/CrmOffersContent/item';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ICrmOffersContent {
    /**
     * Список блоков.
     */
    list: ICrmOffersContentItem[];
    listDbo?: ICrmOffersDBOItem[];
    name?: string;
    desc?: string;
}

const CrmOffersContent: React.FC<ICrmOffersContent> = ({ list, name, desc }) => {
    if (!list || list?.length <= 0)
        return (
            <div className={clsx(cn.topItem, cn.topItemTitle)}>
                К сожалению, персональные предложения для вас не найдены
            </div>
        );

    return (
        <div className={clsx(cn.wrapper)}>
            <div className={cn.innerWrap}>
                <div className={cn.top}>
                    {name && (
                        <div className={clsx(cn.topItem, cn.topItemTitle)}>
                            <TextField
                                text="Лучшие предложения для вас"
                                name="content.name"
                                customTag="h3"
                                className={cn.mainTitle}
                            />
                        </div>
                    )}
                </div>
                <div className={cn.contentGrid}>
                    {list?.map((item, index) => (
                        <div className={cn.contentItem} data-length={list.length} key={index}>
                            <CrmOffersContentItem {...item} pos={index} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(CrmOffersContent);
