import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface ICreditCard {
    _template: 'creditcard';
    title: string;
}

const CreditCardContent = dynamic(async () => import('./CreditCardContent'), { ssr: false });

const CreditCard: React.FC<ICreditCard & { index: number }> = ({ title }) => (
    <div className="section">
        <CreditCardContent title={title} />
    </div>
);

export default withBlockLinks(CreditCard);
