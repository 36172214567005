import { mockCrmOffersDBOData } from '@/components/blocks/frames/CrmOffersContent/staticData';

export const mockCrmOffersBlockData = {
    image: '/img-next/png/crm-offers.png',
    title: 'Мы подготовили для вас персональные предложения',
    content: {
        name: 'Лучшие предложения для вас',
        desc: '',
        list: [
            {
                title: 'Кредит наличными 9,9% 23',
                subtitle: 'с услугой «Гарантированная ставка»',
                image: '/img-next/png/infoCredit/ekv1.png',
                mark: 'До 1 500 000 ₽',
                moreLinkName: 'Оформить',
                moreLinkHref: '#',
            },
            {
                title: 'Рефинансирование от 9,9%',
                subtitle: 'с услугой «Гарантированная ставка»',
                image: '/img-next/png/infoCredit/ekv2.png',
                mark: 'До 1 500 000 ₽',
                moreLinkName: 'Оформить',
                moreLinkHref: '#',
            },
            {
                title: 'Карта «ВездеДоход»',
                subtitle: '120 дней без процентов до 30% бонусами за покупки',
                image: '/img-next/png/infoCredit/ekv2.png',
                mark: 'До 1 500 000 ₽',
                moreLinkName: 'Оформить',
                moreLinkHref: '#',
            },
        ],
        listDbo: mockCrmOffersDBOData.listDbo,
    },
};

export const mockCrmOffersBlockDataTinaData = {
    section: null,
    title: 'Заголовок',
    image: '',
    content: {
        name: 'Заголовок',
        desc: 'Описание',
        list: [
            {
                title: 'Заголовок',
                subtitle: 'Описание',
                image: '',
                mark: '',
                moreLinkName: 'Ссылка',
                moreLinkHref: '#',
            },
        ],
    },
};

export const mockCrmOffersLinkBlockData = {
    name: 'Ваши персональные предложения',
    buttonName: 'Показать предложения',
    link: 'crmOffers',
    buttonType: 'anchor',
    image: '/img-next/png/bell-reminder.png',
};

export const mockCrmOffersLinkBlockTinaData = {
    name: 'Заголовок',
    buttonName: 'Название кнопки',
    link: 'id без решетки или ссылка',
    buttonType: 'anchor',
    image: '',
};
