export const gtagSingleItem = {
    name: 'gtag',
    component: 'group',
    label: 'Цель GTM или Matomo',
    fields: [
        {
            name: 'action',
            component: 'text',
            label: 'eventAction',
        },
        {
            name: 'category',
            component: 'text',
            label: 'eventCategory',
        },
        {
            name: 'label',
            component: 'text',
            label: 'eventLabel',
        },
        {
            name: 'location',
            component: 'text',
            label: 'eventLocation',
        },
        {
            name: 'context',
            component: 'text',
            label: 'eventContext',
        },
    ],
};

export const gtagSingleItemCustom = (name: string, label: string) => ({
    name,
    component: 'group',
    label,
    fields: [
        {
            name: 'eventName',
            component: 'text',
            label: 'event',
        },
        {
            name: 'category',
            component: 'text',
            label: 'eventCategory',
        },
        {
            name: 'action',
            component: 'text',
            label: 'eventAction',
        },
        {
            name: 'label',
            component: 'text',
            label: 'eventLabel',
        },
        {
            name: 'location',
            component: 'text',
            label: 'eventLocation',
        },
        {
            name: 'context',
            component: 'text',
            label: 'eventContext',
        },
    ],
});
