import clsx from 'clsx';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';

import CrmOffersContent, { ICrmOffersContent } from '@/components/blocks/frames/CrmOffersContent';
import { staticData } from '@/components/blocks/frames/CrmOffersContent/staticData';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Title from '@/components/UI/Title';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface ICrmOffers {
    _template: 'crmOffers';
    image?: string;
    title?: string;
    content?: ICrmOffersContent;
}

export interface ICrmResponse {
    type: string;
    maxLimit: string;
    rate: string;
    url: string;
    productName: string;
    productDescription: string;
}

const CrmOffers: React.FC<ICrmOffers> = ({ image, content, title }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isTrackLoaded, setIsTrackLoaded] = useState(false);

    const [isOffer, setIsOffer] = useState(false);

    const [isLoadedOffer, setIsLoadedOffer] = useState(false);
    const [loadedContent, setLoadedContent] = useState({ list: [] });

    const [isError, setIsError] = useState(false);

    const width = useResize();
    const isMobile = width <= MOBILE_WIDTH;

    useEffect(() => {
        const gOldOnError = window.onerror;

        window.onerror = function myErrorHandler(errorMsg: string, url: string, lineNumber: number) {
            if (url.includes('credit-offers') || url.includes('creditOffers') || url.includes('track')) {
                window?.PBSDK?.creditOffers?.unmount('#form-container');
                setIsError(true);
            }
            if (gOldOnError) {
                // Вызвать прошлый обработчик события.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return gOldOnError(errorMsg, url, lineNumber);
            }

            // Просто запустить обработчик события по умолчанию.
            return false;
        };
    });

    const scrollToTopOfCrmBlock = () => {
        const headerHeight = document?.querySelector('.headerBottom')?.getBoundingClientRect()?.height;
        const resultWrapperPosition = document?.querySelector(`.${cn.fullWrapper}`)?.getBoundingClientRect()?.top;

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        const amountOfScrolling = resultWrapperPosition + scrollTop - headerHeight;

        const totalAmountOfScrolling = isMobile ? amountOfScrolling : amountOfScrolling - 70;

        window.scrollTo({
            top: totalAmountOfScrolling,
            behavior: 'smooth',
        });
    };

    const scriptOnLoad = () => {
        setIsLoaded(true);
    };

    const scriptTrackOnLoad = () => {
        setIsTrackLoaded(true);
    };

    const initializeScript = () => {
        if (isError) return;

        window?.PBSDK?.creditOffers?.mountTo('#form-container', {
            theme: 'light',
        });

        // подписка на событие ошибки получения списка предложений или пустого списка
        window?.PBSDK?.creditOffers?.on('notFound', () => {
            scrollToTopOfCrmBlock();

            setIsOffer(true);
        });

        // подписка на событие получения списка предложений
        window?.PBSDK?.creditOffers?.on('loaded', (list: ICrmResponse[]) => {
            scrollToTopOfCrmBlock();

            console.log('Список предложений: ', list);

            const emptyGtag = {
                action: '',
                category: '',
                label: '',
            };

            const newContent: ICrmOffersContent = {
                name: 'Лучшие предложения для вас',
                desc: '',
                list: [],
                listDbo: [],
            };

            list?.map((item, index) =>
                newContent.list.push({
                    pos: index,
                    title: staticData[item.type].title,
                    subtitle: item.productDescription || staticData[item.type].description,
                    image: staticData[item.type].image,
                    moreLinkHref: item.url,
                    mark: item.maxLimit,
                    gtagClick:
                        content?.listDbo?.filter(
                            elem =>
                                elem?.type === item?.type &&
                                (item?.productName ? item?.productName === elem?.productName : true)
                        )[0].gtagClick || emptyGtag,
                    gtagShow:
                        content?.listDbo?.filter(
                            elem =>
                                elem?.type === item?.type &&
                                (item?.productName ? item?.productName === elem?.productName : true)
                        )[0].gtagShow || emptyGtag,
                })
            );

            setLoadedContent(newContent);
            setIsLoadedOffer(true);
        });

        // подписка на событие ожидания получения предложений
        window?.PBSDK?.creditOffers?.on('loading', () => {
            console.log('ждем предложения');
        });
    };

    useEffect(() => {
        if (isLoaded && isTrackLoaded && !isError) {
            initializeScript();
        }
    }, [isLoaded, isTrackLoaded, isError]);

    if (isError) return null;

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <Script
                    src="https://my.pochtabank.ru/sdk/v1/credit-offers.js"
                    strategy="afterInteractive"
                    onLoad={scriptOnLoad}
                />
                <Script
                    src="https://my.pochtabank.ru/sdk/v1/track.js"
                    strategy="afterInteractive"
                    onLoad={scriptTrackOnLoad}
                />
                {!isOffer && !isLoadedOffer && (
                    <div className={clsx(cn.wrapper, cn.extWrapper)}>
                        <div className={clsx(cn.item, cn.itemLeft)}>
                            <Title title={title} level={3} name="title" />
                            <div id="form-container" />
                        </div>
                        <div className={clsx(cn.item, cn.itemRight)}>
                            <div className={cn.image}>
                                <CustomImage src={image} alt="CRM-предложения" mockWidth="439" mockHeight="368" fill />
                                <div className={cn.advantagesBotDecor} />
                            </div>
                        </div>
                    </div>
                )}

                {isLoadedOffer && (
                    <div className={clsx(cn.innerContent, cn.extWrapper)}>
                        <CrmOffersContent {...loadedContent} />
                    </div>
                )}

                {isOffer && (
                    <div className={clsx(cn.innerContent, cn.extWrapper)}>
                        <CrmOffersContent {...content} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withBlockLinks(CrmOffers);
