import React from 'react';

import { TinaCrmOffers } from '@/components/blocks/frames/CrmOffers/index.tina';
import { mockCrmOffersDBOData } from '@/components/blocks/frames/CrmOffersContent/staticData';
import { mockCrmOffersBlockData } from '@/data/blocks/crmOffers';
import { gtagSingleItemCustom } from '@/tina/utils/getGTAGConfig';
import { IBlockProps } from '@/types/tina/block';

export const crmOffersBlock = {
    Component: (props: IBlockProps) => <TinaCrmOffers {...props} />,
    template: {
        label: 'CRM-предложения на главной',
        defaultItem: () => mockCrmOffersBlockData,
        fields: [
            {
                name: 'image',
                component: 'image',
                label: 'Картинка',
                parse: (media: { src: string }) => media.src,
            },

            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },

            {
                label: 'Внутренний контент',
                name: 'content',
                component: 'group',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        label: 'Заголовок',
                    },
                    {
                        name: 'desc',
                        component: 'text',
                        label: 'Описание',
                    },
                    {
                        label: 'Список элементов по умолчанию',
                        name: 'list',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => mockCrmOffersBlockData.content.list[0],
                        fields: [
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'subtitle',
                                component: 'textarea',
                                label: 'Описание',
                            },
                            {
                                name: 'mark',
                                component: 'textarea',
                                label: 'Дополнительное описание',
                            },
                            {
                                name: 'image',
                                component: 'image',
                                label: 'Картинка',
                                parse: (media: { src: string }) => media.src,
                            },
                            {
                                name: 'moreLinkHref',
                                component: 'text',
                                label: 'Ссылка',
                            },
                            {
                                name: 'moreLinkName',
                                component: 'text',
                                label: 'Ссылка: название',
                            },
                            { ...gtagSingleItemCustom('gtagClick', 'Цель на клик') },
                            { ...gtagSingleItemCustom('gtagShow', 'Цель на появление') },
                        ],
                    },

                    {
                        label: 'Список элементов из DBO',
                        name: 'listDbo',
                        component: 'group-list',
                        itemProps: (item: { title: string }, index: number) => ({
                            key: index,
                            label: item.title,
                        }),
                        defaultItem: () => mockCrmOffersDBOData.listDbo[0],
                        fields: [
                            {
                                name: 'title',
                                component: 'text',
                                label: 'Заголовок',
                            },
                            {
                                name: 'type',
                                component: 'text',
                                label: 'type',
                            },
                            {
                                name: 'productName',
                                component: 'text',
                                label: 'productName',
                            },
                            { ...gtagSingleItemCustom('gtagClick', 'Цель на клик') },
                            { ...gtagSingleItemCustom('gtagShow', 'Цель на появление') },
                        ],
                    },
                ],
            },
        ],
    },
};
