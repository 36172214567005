import React from 'react';

import { TinaDepositApplication } from '@/components/blocks/frames/DepositApplication/index.tina.deprecated';
import { IBlockProps } from '@/types/tina/block';

interface IExtendedBlockProps extends IBlockProps {
    index: number;
}

export const depositApplicationBlock = {
    Component: (props: IBlockProps) => {
        const { data, index } = props;

        const extendedProps: IExtendedBlockProps = {
            ...props,
            data: {
                ...data,
                index,
            },
        };

        return <TinaDepositApplication {...extendedProps} />;
    },
    template: {
        label: 'Онлайн заявка на оформление вклада',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
        ],
    },
};
