import React from 'react';

import { TinaMapContainerForStaticCity } from '@/components/blocks/frames/MapContainerForStaticCity/index.tina';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

export const mapContainerForStaticCityBlock = {
    Component: (props: IBlockProps) => <TinaMapContainerForStaticCity {...props} />,
    template: {
        label: 'Карта отделений и банкоматов для статичного города',
        defaultItem: () => ({
            title: 'Заголовок',
        }),
        fields: [
            {
                name: 'title',
                component: HtmlEditorFeatured,
                label: 'Заголовок',
            },
        ],
    },
};
