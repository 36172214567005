import dynamic from 'next/dynamic';
import React, { useContext, useLayoutEffect, useState } from 'react';

import withBlocksControl from '@/components/HOC/withBlocksControl';
import TabContext from '@/context/TabContext';
import { IBlockProps } from '@/types/tina/block';

const Block = dynamic(() => import('./index'));

const WrappedBlock = withBlocksControl(Block);

export function TinaVddohod(props: IBlockProps & { index: number }) {
    const { index } = props;
    const { activeTab } = useContext(TabContext);
    const [isActive, setIsActive] = useState(activeTab === index);

    useLayoutEffect(() => {
        setIsActive(activeTab === index);
    }, [activeTab, index, isActive]);

    if (!isActive) return null;

    return <WrappedBlock {...props} />;
}
