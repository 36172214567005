import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IUniversalExternalIframe {
    _template: 'universalExternalIframe';
    frame?: string;
}

const UniversalExternalIframeContent = dynamic(async () => import('./UniversalExternalIframeContent'), { ssr: false });

const UniversalExternalIframe: React.FC<IUniversalExternalIframe> = ({ frame }) => (
    <div className="section">
        <UniversalExternalIframeContent frame={frame} />
    </div>
);

export default withBlockLinks(UniversalExternalIframe);
