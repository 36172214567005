import React from 'react';

import { TinaVkladDesyatochka } from '@/components/blocks/frames/VkladDesyatochka/index.tina';
import { IBlockProps } from '@/types/tina/block';

export const vkladDesyatochkaBlock = {
    Component: (props: IBlockProps) => <TinaVkladDesyatochka {...props} />,
    template: {
        label: 'Форма вклада "Десяточка"',
        defaultItem: () => ({
            title: 'Заголовок',
            withoutRedirect: false,
        }),
        fields: [
            {
                name: 'title',
                component: 'text',
                label: 'Заголовок',
            },
            {
                name: 'withoutRedirect',
                component: 'select',
                label: 'Редирект',
                options: [
                    {
                        value: 'false',
                        label: 'С редиректом',
                    },
                    {
                        value: 'true',
                        label: 'Без редиректа',
                    },
                ],
            },
        ],
    },
};
