import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import useBeingInViewport from '@/hooks/useBeingInViewport';

export const withComponentView = <T,>(Component: React.FC<T>) => {
    const Block: React.FC<T> = observer((props: T) => {
        const blockLink = useRef<HTMLDivElement | null>(null);

        const isVisible = useBeingInViewport(blockLink, 0, true);

        const [showScript, setShowScript] = useState(false);

        useEffect(() => {
            if (showScript) return;
            if (isVisible) {
                setShowScript(true);
            }
        }, [isVisible]);

        return <div ref={blockLink}>{showScript && <Component {...props} isVisible={isVisible} />}</div>;
    });

    return Block;
};
