import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';

export interface IUniversalExternalFormScript {
    _template: 'universalExternalFormScript';
    title?: string;
    script: string;
}

const UniversalExternalFormScriptContent = dynamic(async () => import('./UniversalExternalFormScriptContent'), {
    ssr: false,
});

const UniversalExternalFormScript: React.FC<IUniversalExternalFormScript> = ({ title, script }) => (
    <div className="section">
        <UniversalExternalFormScriptContent title={title} script={script} />
    </div>
);

export default withBlockLinks(UniversalExternalFormScript);
