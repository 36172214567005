import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IPensionTransfer {
    _template: 'pensionTransferV2';
    title: string;
    gtm?: GTagEvent;
}

const PensionTransferV2Content = dynamic(async () => import('./PensionTransferV2Content'), {
    ssr: false,
});

const PensionTransferV2: React.FC<IPensionTransfer & { index: number }> = ({ index, title, gtm }) => (
    <div className="section">
        <PensionTransferV2Content title={title} index={index} gtm={gtm} />
    </div>
);

export default withBlockLinks(PensionTransferV2);
