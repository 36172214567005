import dynamic from 'next/dynamic';
import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IVkladDesyatochka {
    _template: 'vkladDesyatochka';
    title: string;
    withoutRedirect: boolean;
    gtagClick?: GTagEvent;
}

const VkladDesyatochkaContent = dynamic(async () => import('./VkladDesyatochkaContent'), {
    ssr: false,
});

function VkladDesyatochka({ index, title, withoutRedirect, gtagClick }: IVkladDesyatochka & { index: number }) {
    return (
        <div className="section">
            <VkladDesyatochkaContent
                title={title}
                index={index}
                withoutRedirect={withoutRedirect}
                gtagClick={gtagClick}
            />
        </div>
    );
}

export default withBlockLinks(VkladDesyatochka);
