import dynamic from 'next/dynamic';
import { useParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { withComponentView } from '@/components/HOC/withComponentView';
import { withExternalFrameView } from '@/components/HOC/withExternalFrameView';
import Feature from '@/domain/feature/Feature';
import { IStaticCityResponse } from '@/types/city/city';
import { apiRequest } from '@/utils/request';

export interface IMapContainerForStaticCity {
    _template: 'mapContainerForStaticCity';
    title?: string;
}

const MapContainerForStaticCityContent = dynamic(async () => import('./MapContainerForStaticCityContent'), {
    ssr: false,
});

const MapContainerForStaticCity: React.FC<IMapContainerForStaticCity> = ({ title }) => {
    const params = useParams<{ slug: string[] }>();
    const [currentCoordinates, setCurrentCoordinates] = useState<number[]>(null);

    const fetchInfoCity = async () => {
        if (!params.slug) return;

        const staticCity = params.slug[params.slug.length - 1];

        try {
            const {
                data: { data },
            } = await apiRequest.get<IStaticCityResponse>(`/city/coordinates?city=${staticCity}`);

            if (data.city) {
                setCurrentCoordinates([data.latitude, data.longitude]);
            } else {
                setCurrentCoordinates([59.9342, 30.335]); // Санкт-Петербург
            }
        } catch (e) {
            setCurrentCoordinates([59.9342, 30.335]); // Санкт-Петербург
            console.log('Error:::', e);
        }
    };

    useEffect(() => {
        fetchInfoCity().catch(e => console.log(e));
    }, []);

    if (!Feature.isFeatureEnabled('enableMapContainerForStaticCity')) {
        return null;
    }

    return (
        <div className="section">
            {currentCoordinates && (
                <MapContainerForStaticCityContent title={title} currentCoordinates={currentCoordinates} />
            )}
        </div>
    );
};

export default withBlockLinks(withComponentView(withExternalFrameView(MapContainerForStaticCity)));
